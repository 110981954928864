import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DATE_LOCALE, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FnPipe } from '@shared/pipes/fn.pipe';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { EmptyPageComponent } from '@shared/components/placeholder/empty-page/empty-page.component';
import { IterablePipe } from '@shared/pipes/iterable.pipe';
import { TableComponent } from '@shared/components/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { InfoSectionComponent } from '@shared/components/placeholder/info-section/info-section.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { SectionSideNavComponent } from '@shared/components/side-nav/section-side-nav/section-side-nav.component';
import { TemplateHostDirective } from '@shared/directives/template-host.directive';
import { SectionContentComponent } from '@shared/components/side-nav/section-content/section-content.component';
import { SectionComponent } from '@shared/components/side-nav/section/section.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderDirective } from './directives/loader.directive';
import { TypeItemComponent } from '@shared/components/type-item/type-item.component';
import { StageItemComponent } from '@shared/components/stage-item/stage-item.component';
import { SelectComponent } from '@shared/components/popup/select/select.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ConfirmDialogComponent } from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { ConfettiDialogComponent } from './components/confetti-dialog/confetti-dialog.component';
import { GradientIconViewComponent } from '@shared/components/gradient-icon-view/gradient-icon-view.component';
import { SwipeableDirective } from '@shared/components/swipe-stack/swipe-card-wrapper/swipeable.directive';
import { SwipeCardWrapperComponent } from '@shared/components/swipe-stack/swipe-card-wrapper/swipe-card-wrapper.component';
import { BorderedIconButtonComponent } from '@shared/components/bordered-icon-button/bordered-icon-button.component';
import { MatchItemComponent } from './components/match-item/match-item.component';
import { MatCardModule } from '@angular/material/card';
import { MascotDialogComponent } from './components/mascot-dialog/mascot-dialog.component';
import { StoryGuideComponent } from '@shared/components/story-guide/story-guide.component';
import { AutoTypeSpeechBubbleComponent } from '@shared/components/auto-type-speech-bubble/auto-type-speech-bubble.component';
import { MatButtonLoadingDirective } from '@shared/directives/mat-button-loading.directive';
import { SafePipe } from './pipes/safe.pipe';
import { SelectableDirective } from './directives/selectable.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JobTypeToValuePipe } from '@shared/pipes/job-type-to-value.pipe';
import { MapComponent } from './components/map/map.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import {
  CarouselItemDirective,
  CarouselItemElement,
} from '@shared/components/carousel/carousel.directive';
import { UrlPipe } from './pipes/url.pipe';
import { LocationSelectorComponent } from '@shared/components/location-selector/location-selector.component';
import { DehtmlPipe } from './pipes/dehtml.pipe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TableCellDirective,
    FnPipe,
    EmptyPageComponent,
    IterablePipe,
    TableComponent,
    InfoSectionComponent,
    SectionSideNavComponent,
    SectionComponent,
    StoryGuideComponent,
    SectionContentComponent,
    TemplateHostDirective,
    LoaderComponent,
    AutoTypeSpeechBubbleComponent,
    LoaderDirective,
    TypeItemComponent,
    StageItemComponent,
    SelectComponent,
    ConfirmDialogComponent,
    ConfettiDialogComponent,
    GradientIconViewComponent,
    SwipeCardWrapperComponent,
    SwipeableDirective,
    BorderedIconButtonComponent,
    MatchItemComponent,
    MascotDialogComponent,
    MatButtonLoadingDirective,
    SafePipe,
    SelectableDirective,
    JobTypeToValuePipe,
    MapComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    UrlPipe,
    LocationSelectorComponent,
    DehtmlPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    ScrollingModule,
    MatRippleModule,
    MatDialogModule,
    MatMenuModule,
    MatStepperModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    FlexLayoutModule,
    DragDropModule,
    FormsModule,
    RouterModule,
    NgOptimizedImage,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FnPipe,
    TableComponent,
    TableCellDirective,
    SectionSideNavComponent,
    LoaderComponent,
    StoryGuideComponent,
    LoaderDirective,
    StageItemComponent,
    TypeItemComponent,
    IterablePipe,
    SectionContentComponent,
    MatchItemComponent,
    EmptyPageComponent,
    MascotDialogComponent,
    GradientIconViewComponent,
    SwipeCardWrapperComponent,
    SwipeableDirective,
    BorderedIconButtonComponent,
    MatButtonLoadingDirective,
    SafePipe,
    SelectableDirective,
    JobTypeToValuePipe,
    MapComponent,
    CarouselComponent,
    CarouselItemDirective,
    UrlPipe,
    TemplateHostDirective,
    LocationSelectorComponent,
    DehtmlPipe,
    SelectComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }],
})
export class SharedModule {}
