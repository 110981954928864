import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-gradient-icon-view',
  templateUrl: './gradient-icon-view.component.html',
  styleUrls: ['./gradient-icon-view.component.scss'],
})
export class GradientIconViewComponent {
  @Input() text: string | undefined | null;

  @Input() icon: string | null = null;

  @Input() src: string | null = null;
}
