import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Match, MatchStatus } from '@data/match/match.model';
import { shareReplay } from 'rxjs/operators';
import { Company } from '@data/company/company.model';
import { EMPTY, Observable, of } from 'rxjs';
import { FileData } from '@data/file/file.model';
import { FileRepository } from '@data/file/file.store';
import { JobGeneral } from '@data/job-general/job-general.model';
import { Job } from '@data/job/job.model';
import { ImportedCompany } from '@data/imported-company/imported-company.model';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class AbstractMatchItemComponent implements OnInit {
  MatchStatus = MatchStatus;

  @Input() match!: Match;

  @Input() company?: Company;

  @Input() isImported?: boolean;

  @Input() jobGeneral?: JobGeneral | null;

  @Input() job?: Job | null = null;

  @Input() icon!: string | null;

  companyLogo$: Observable<FileData | undefined> = of(undefined);

  constructor(private fileRepo: FileRepository) {}

  get jobName() {
    if (this.isImported) {
      return (
        (this.company as ImportedCompany)?.jobsData?.[this.match.jobTypeId]?.identifiableName ??
        this.jobGeneral?.name.de
      );
    } else {
      return this.job?.identifiableName ?? this.jobGeneral?.name.de;
    }
  }

  ngOnInit() {
    this.loadFiles();
  }

  protected loadFiles() {
    this.companyLogo$ = this.loadCompanyLogo().pipe(shareReplay(1));
  }

  private loadCompanyLogo() {
    if (!this.company) {
      return EMPTY;
    }
    return this.fileRepo.downloadCompanyLogo(this.company?.id, this.isImported);
  }
}
