import {
  selectAllEntities,
  selectEntity,
  selectManyByPredicate,
  setEntities,
  updateEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { Store } from '@ngneat/elf';
import { first, Observable, tap } from 'rxjs';
import { EntityCrudService, IHTTPParamsObject } from '@data/entity-crud.service';
import {
  clearRequestsCache,
  createRequestsCacheOperator,
  deleteRequestResult,
  joinRequestResult,
  trackRequestResult,
  updateRequestCache,
} from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

export abstract class EntityRepository<T> {
  all$: Observable<T[]> = this.requireStore().pipe(selectAllEntities());

  skipWhileCached;

  protected store!: Store;

  protected constructor(protected service: EntityCrudService<T, T>) {
    this.store = this.requireStore();
    this.skipWhileCached = createRequestsCacheOperator(this.store);
  }

  isSuccess$ = () =>
    this.requireStore().pipe(
      selectAllEntities(),
      joinRequestResult([this.requireStore().name]),
      map((it) => it.isSuccess),
    );

  isEmpty$ = () =>
    this.requireStore().pipe(
      selectAllEntities(),
      joinRequestResult([this.requireStore().name]),
      map((it) => !it.data?.length),
    );

  isLoading$ = () =>
    this.requireStore().pipe(
      selectAllEntities(),
      joinRequestResult([this.requireStore().name]),
      map((it) => it.isLoading),
    );

  select(filter: Partial<T>) {
    return this.store.pipe(selectManyByPredicate((it) => this.matches(it, filter)));
  }

  getById(id?: string): Observable<T | undefined> {
    return this.store.pipe(selectEntity(id));
  }

  set(entities: T[]) {
    this.store.update(updateRequestCache(this.store.name), setEntities(entities));
  }

  upsert(entities: T[]) {
    const id = (entities[0] as any).id ?? (entities[0] as any)._id;
    this.store.update(updateRequestCache(this.store.name + id), upsertEntities(entities));
  }

  loadById(id: string) {
    return this.service.getById(id).pipe(
      tap((entity) => {
        if (!entity) return;
        this.upsert([entity]);
      }),
      this.skipWhileCached(this.store.name + id, { returnSource: this.getById(id).pipe(first()) }),
    );
  }

  loadAll(params?: IHTTPParamsObject) {
    //clear cache if only one entity is in store
    if (this.store.getValue().ids.length === 1) {
      this.clearCache();
    }
    return this.service.get(params).pipe(
      tap((entities) => this.set(entities ?? [])),
      trackRequestResult([this.store.name]),
      this.skipWhileCached(this.store.name, {
        returnSource: this.all$.pipe(first()),
      }),
    );
  }

  updateEntity(id: string, entity: Partial<T>) {
    return this.service.update(id, entity).pipe(
      tap((x) => {
        this.store.update(updateRequestCache(this.store.name + id), updateEntities(id, x));
      }),
    );
  }

  reset() {
    this.store.reset();
    deleteRequestResult([this.store.name]);
    this.clearCache();
  }

  clearCache() {
    deleteRequestResult([this.store.name]);
    this.store.update(clearRequestsCache());
  }

  protected abstract requireStore(): Store;

  private matches(entity: T, filter: Partial<T>) {
    const strings = Object.keys(filter) as (keyof T)[];
    const keys = strings.filter((key) => filter[key] !== undefined);
    return keys.every((key) => entity[key] === filter[key]);
  }
}
