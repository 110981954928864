import { createState, Store } from '@ngneat/elf';
import {
  selectEntityByPredicate,
  selectManyByPredicate,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { createFromBlob, createFromDTO, FileData } from '@data/file/file.model';
import { FileService } from '@data/file/file.service';
import {
  createRequestsCacheOperator,
  updateRequestCache,
  withRequestsCache,
} from '@ngneat/elf-requests';
import { switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

const { state, config } = createState(withEntities<FileData>(), withRequestsCache());

const fileStore = new Store({ name: 'file', state, config });
const skipWhileCached = createRequestsCacheOperator(fileStore);

@Injectable({ providedIn: 'root' })
export class FileRepository {
  protected constructor(private fileService: FileService) {}

  downloadJobBanner(id: string) {
    return this.fileService.download('jobs', 'job/banner', id, 'banner').pipe(
      map((document) => createFromBlob(document, id, 'banner', 'job/banner')),
      tap((document) => {
        return fileStore.update(
          updateRequestCache(`jobs/job/banner/${id}/banner`),
          upsertEntities([document]),
        );
      }),
      skipWhileCached(`jobs/job/banner/${id}/banner`, {
        returnSource: this.selectJobBanner(id),
      }),
    );
  }

  downloadCompanyLogo(id: string, isImported = false) {
    const realm = isImported ? 'external-companies' : 'companies';
    return this.fileService.download(realm, 'company/logo', id, 'logo').pipe(
      map((document) => createFromBlob(document, id, 'logo', 'company/logo')),
      tap((document) => {
        return fileStore.update(
          updateRequestCache(`${realm}/company/logo/${id}/logo`),
          upsertEntities([document]),
        );
      }),
      skipWhileCached(`${realm}/company/logo/${id}/logo`, {
        returnSource: this.selectCompanyLogo(id),
      }),
    );
  }

  downloadCompanyBanner(id: string) {
    return this.fileService.download('companies', 'company/banner', id, 'banner').pipe(
      map((document) => createFromBlob(document, id, 'banner', 'company/banner')),
      tap((document) => {
        return fileStore.update(
          updateRequestCache(`companies/company/banner/${id}/banner`),
          upsertEntities([document]),
        );
      }),
      skipWhileCached(`companies/company/banner/${id}/banner`, {
        returnSource: this.selectCompanyBanner(id),
      }),
    );
  }

  downloadJobMedia(id: string, name: string) {
    return this.fileService.download('jobs', 'job/media', id, name).pipe(
      map((document) => createFromBlob(document, id, name, 'job/media')),
      tap((document) => {
        return fileStore.update(
          updateRequestCache(`jobs/job/media/${id}/${name}`),
          upsertEntities([document]),
        );
      }),
      skipWhileCached(`jobs/job/media/${id}/${name}`, {
        returnSource: this.selectJobMedium(id, name),
      }),
    );
  }

  downloadCompanyMedia(id: string, name: string) {
    return this.fileService.download('companies', 'company/media', id, name).pipe(
      map((document) => createFromBlob(document, id, name, 'company/media')),
      tap((document) => {
        return fileStore.update(
          updateRequestCache(`companies/company/media/${id}/${name}`),
          upsertEntities([document]),
        );
      }),
      skipWhileCached(`companies/company/media/${id}/${name}`, {
        returnSource: this.selectCompanyMedium(id, name),
      }),
    );
  }

  downloadCompanyDocument(id: string, name: string) {
    return this.fileService.download('companies', 'company/document', id, name).pipe(
      map((document) => createFromBlob(document, id, name, 'company/document')),
      tap((document) => fileStore.update(upsertEntities([document]))),
      skipWhileCached(`companies/company/document/${id}/${name}`, {
        returnSource: this.selectCompanyDocument(id, name),
      }),
    );
  }

  getJobMediaMetadata(id: string) {
    return this.fileService.meta('jobs', 'job/media', id).pipe(
      map((documents) => documents.map((doc) => createFromDTO(doc, id, 'job/media'))),
      tap((document) => fileStore.update(upsertEntities(document))),
      skipWhileCached(`jobs/job/media/${id}`, {
        returnSource: this.selectJobMedia(id),
      }),
      switchMap(() => this.selectJobMedia(id)),
    );
  }

  getCompanyMediaMetadata(id: string) {
    return this.fileService.meta('companies', 'company/media', id).pipe(
      map((documents) => documents.map((doc) => createFromDTO(doc, id, 'company/media'))),
      tap((document) => fileStore.update(upsertEntities(document))),
      skipWhileCached(`companies/company/media/${id}`, {
        returnSource: this.selectCompanyMedia(id),
      }),
      switchMap(() => this.selectCompanyMedia(id)),
    );
  }

  getCompanyDocumentsMetadata(id: string) {
    return this.fileService.meta('companies', 'company/document', id).pipe(
      map((documents) => documents.map((doc) => createFromDTO(doc, id, 'company/document'))),
      tap((document) => fileStore.update(upsertEntities(document))),
      skipWhileCached(`companies/company/document/${id}`, {
        returnSource: this.selectCompanyDocuments(id),
      }),
      switchMap(() => this.selectCompanyDocuments(id)),
    );
  }

  selectCompanyMedia(id: string) {
    return fileStore.pipe(
      selectManyByPredicate((it) => it.docType === 'company/media' && it.forId === id),
      map((it) => it.sort((a, b) => a.type.localeCompare(b.type))),
    );
  }

  selectCompanyMedium(id: string, name: string) {
    return fileStore.pipe(
      selectEntityByPredicate(
        (it) => it.docType === 'company/media' && it.forId === id && it.name === name,
      ),
    );
  }

  selectCompanyDocuments(id: string) {
    return fileStore.pipe(
      selectManyByPredicate((it) => it.docType === 'company/document' && it.forId === id),
      map((it) => it.sort((a, b) => a.type.localeCompare(b.type))),
    );
  }

  selectCompanyDocument(id: string, name: string) {
    return fileStore.pipe(
      selectEntityByPredicate(
        (it) => it.docType === 'company/document' && it.forId === id && it.name === name,
      ),
    );
  }

  selectJobMedia(id: string) {
    return fileStore.pipe(
      selectManyByPredicate((it) => it.docType === 'job/media' && it.forId === id),
      map((it) => it.sort((a, b) => a.type.localeCompare(b.type))),
    );
  }

  selectJobMedium(id: string, name: string) {
    return fileStore.pipe(
      selectEntityByPredicate(
        (it) => it.docType === 'job/media' && it.forId === id && it.name === name,
      ),
    );
  }

  selectCompanyLogo(id: string) {
    return fileStore.pipe(
      selectEntityByPredicate((it) => it.docType === 'company/logo' && it.forId === id),
    );
  }

  selectCompanyBanner(id: string) {
    return fileStore.pipe(
      selectEntityByPredicate((it) => it.docType === 'company/banner' && it.forId === id),
    );
  }

  selectJobBanner(id: string) {
    return fileStore.pipe(
      selectEntityByPredicate((it) => it.docType === 'job/banner' && it.forId === id),
    );
  }
}
