import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ToolbarLayoutComponent } from './toolbar-layout/toolbar-layout.component';
import { PrimaryBgLayoutComponent } from './primary-bg-layout/primary-bg-layout.component';
import { FooterComponent } from './footer/footer.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { InfoCardLayoutComponent } from './info-card-layout/info-card-layout.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { FooterOnlyLayoutComponent } from './footer-only-layout/footer-only-layout.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PoweredByLayoutComponent } from './powered-by-layout/powered-by-layout.component';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [
    ToolbarLayoutComponent,
    PrimaryBgLayoutComponent,
    FooterComponent,
    InfoCardLayoutComponent,
    FooterOnlyLayoutComponent,
    PoweredByLayoutComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    MatToolbarModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    ExtendedModule,
    NgOptimizedImage,
  ],
  exports: [],
})
export class LayoutModule {}
