import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { SW_SWIPE_CARD_DEFAULT_OPTIONS, SWSwipeCardConfig } from './swipe-card.config';

@Directive({
  selector: '[recrewtSwipeable]',
})
export class SwipeableDirective implements OnInit, OnDestroy, OnChanges {
  @HostBinding('style.width.px')
  @Input()
  public width!: number;

  @HostBinding('style.height.px')
  @Input()
  public height!: number;

  @Input()
  public fixed: Boolean = false;

  @Input()
  public orientation = 'xy';

  @Output()
  public released = new EventEmitter();

  @Output()
  public swiped = new EventEmitter<{ like: boolean; data: any }>();

  @HostBinding('class.swipe-card-heap')
  public className = true;

  private readonly animationDuration = 200;

  private isReleased: Boolean = false;

  private readonly element: HTMLElement;

  private overlay: HTMLElement | null = null;

  private releaseRadius!: { x: number; y: number };

  private timeoutId?: number;

  constructor(
    @Inject(SW_SWIPE_CARD_DEFAULT_OPTIONS) private _defaultConfig: SWSwipeCardConfig,
    protected el: ElementRef,
    public renderer: Renderer2,
  ) {
    this.element = el.nativeElement;
  }

  get allowSwipe(): boolean {
    return !this.fixed && !this.isReleased;
  }

  @HostListener('pan', ['$event'])
  onPan(event: any) {
    if (this.allowSwipe) {
      this.handleSwipeChange(event);
    }
  }

  @HostListener('panend', ['$event'])
  onPanEnd(event: any) {
    if (this.allowSwipe) {
      const conditionX =
        this.orientation.includes('x') && this.releaseRadius.x < Math.abs(event.deltaX || 0);
      const conditionY =
        this.orientation.includes('y') && this.releaseRadius.y < Math.abs(event.deltaY || 0);
      const like = this.isSwipePositive(event);

      if (conditionX || conditionY) {
        this.handleSwipeEnd(like, null);
      } else {
        this.handleCancelSwipe();
      }
    }
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeoutId);
  }

  ngOnInit(): void {
    this.initializeOverlay();
    this.releaseRadius = { x: this.width / 4, y: this.height / 4 };
  }

  ngOnChanges() {
    this.releaseRadius = { x: this.width / 4, y: this.height / 4 };
  }

  public simulateSwipe(like: boolean, data: any): void {
    this.handleSwipeEnd(like, data);
  }

  private initializeOverlay(): void {
    this.overlay = document.createElement('div');
    this.renderer.appendChild(this.element, this.overlay);
  }

  private translate({ x, y, rotate = 0, duration = 0 }: any) {
    const positionX = x && this.orientation.includes('x') ? x : 0;
    const positionY = y && this.orientation.includes('y') ? y : 0;

    this.renderer.setStyle(this.element, 'transition', `transform ${duration}ms ease`);
    this.renderer.setStyle(
      this.element,
      'webkitTransform',
      `translate3d(${positionX}px, ${positionY}px, 0) rotate(${rotate}deg)`,
    );
  }

  private handleSwipeChange(event: any) {
    const like = this.isSwipePositive(event);
    const opacity = (Math.abs(event.distance) * 0.5) / this.element.offsetWidth;

    this.setBackgroundOverlay(like, opacity);
    this.translate({
      x: event.deltaX,
      y: event.deltaY,
      rotate: (event.deltaX * 20) / this.element.clientWidth,
    });
  }

  private handleCancelSwipe() {
    this.resetBackgroundOverlay();
    this.translate({
      x: 0,
      y: 0,
      rotate: 0,
      duration: this.animationDuration,
    });
  }

  private destroy() {
    this.resetElementStyles();
    this.resetBackgroundOverlay();
    this.renderer.removeChild(this.element, this.overlay);
    this.overlay = null;
  }

  private setBackgroundOverlay(like: boolean, opacity: number) {
    const color = this._defaultConfig.likeColor;
    this.overlay!.className = like ? 'swipe-card-overlay-right' : 'swipe-card-overlay-left';
    this.renderer.setStyle(this.overlay, 'transition', 'opacity 0s ease');
    this.renderer.setStyle(
      this.overlay,
      'background-color',
      `rgba(${color}, ${opacity.toString()})`,
    );
  }

  private resetBackgroundOverlay(): void {
    this.renderer.setStyle(this.overlay, 'transition', `opacity ${this.animationDuration}ms ease`);
    this.renderer.setStyle(this.overlay, 'background-color', 'rgba(255,255,255,0)');
  }

  private resetElementStyles(): void {
    this.renderer.setStyle(this.element, 'transition', 'transform 0s ease');
    this.renderer.setStyle(this.element, 'webkitTransform', 'translate3d(0, 0, 0) rotate(0deg)');
  }

  private isSwipePositive(event: any): boolean {
    return (
      (this.orientation === 'y' && event.deltaY < 0) ||
      (this.orientation !== 'y' && event.deltaX > 0)
    );
  }

  private removeOverlay(like: boolean): void {
    const { offsetHeight, offsetWidth, clientHeight, clientWidth } = this.element;
    const y = (offsetHeight + clientHeight) * (like ? -1 : 1);
    const x = (offsetWidth + clientWidth) * (like ? 1 : -1);

    this.setBackgroundOverlay(like, 0.35);
    this.translate({
      x,
      y,
      rotate: (x * 20) / clientWidth,
      duration: 800,
    });
  }

  private handleSwipeEnd(like: boolean, data: any): void {
    this.removeOverlay(like);
    this.isReleased = true;

    this.timeoutId = window.setTimeout(() => {
      this.swiped.emit({ like, data });
      this.released.emit();
      this.destroy();
    }, this.animationDuration);
  }
}
