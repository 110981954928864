import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as confetti from 'canvas-confetti';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { appConfig } from '../../../../app.config';

@Component({
  selector: 'recrewt-confetti-dialog',
  templateUrl: './confetti-dialog.component.html',
  styleUrls: ['./confetti-dialog.component.scss'],
})
export class ConfettiDialogComponent implements OnInit {
  @ViewChild('confettiCanvas') confettiCanvas!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ConfettiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    setTimeout(() => this.confetti(), 250);
  }

  confetti() {
    const confettiInstance = confetti.create(this.confettiCanvas.nativeElement, { resize: true });
    confettiInstance({
      angle: 90,
      gravity: 1.5,
      particleCount: 500,
      colors: [appConfig.theme.primary, appConfig.theme.accent],
      shapes: ['square', 'circle'],
      spread: 180,
      ticks: 600,
      startVelocity: 45,
    });
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }

  actionClicked(value: any): void {
    this.dialogRef.close(value);
  }
}
